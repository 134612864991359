import type { ApiResponse } from '@/composables/api/types';
import {
  STORE_KEY,
  PLAYED_HANDLE_ID_KEY,
  TIMEZONE,
  ROOM_TOKENS_KEY
} from '@/stores/tournaments/constants';
import type { MomentInput } from 'moment/moment';
import {
  type Tournament,
  type Handle,
  HandleStatus,
  type RoomTokenData,
  type RoomTokenPayload
} from '@/types/tournaments';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import useAppOrigin from '@/composables/helpers/useAppOrigin';
import { useCurrentTournament } from '@/composables/api/actions/tournaments';
import {
  useCreateRoom,
  useCreateRoomAnonymously
} from '@/composables/api/actions/handles';
import { useAuthStore } from '@/stores/auth';
import { useStatisticStore } from '@/stores/statistic';
import { useLevelProgressStore } from '@/stores/statistic/levelProgress';
import useToast from '@/composables/helpers/useToast';

export const useTournamentStore = defineStore(
  STORE_KEY,
  () => {
    const config = useRuntimeConfig();
    const toast = useToast();
    const authStore = useAuthStore();
    const { getStatisticPageData } = useStatisticStore();
    const { getFourWeeksData } = useLevelProgressStore();

    const weeklyTournament = ref<Tournament | undefined>();
    const anonymouslyPlayedHandleId = ref<string | undefined>();
    const roomTokens = ref<RoomTokenData[]>([]);

    const weeklyHandles = computed<Handle[]>(() => {
      if (weeklyTournament.value) {
        return weeklyTournament.value.handles.map((handle) => {
          return handle;
        });
      }

      return [];
    });

    const firstHandle = computed<Handle | undefined>(() => {
      return weeklyHandles.value[0];
    });

    const currentHandle = computed<Handle | undefined>(() => {
      return weeklyHandles.value.find(
        (handle) => handle.status === HandleStatus.Ongoing
      );
    });

    const ongoingHandleExists = computed(() => {
      return currentHandle.value?.status === HandleStatus.Ongoing;
    });

    const nextHandle = computed<Handle | undefined>(() => {
      return weeklyHandles.value.find(
        (handle) => handle.status === HandleStatus.Upcoming
      );
    });

    const timeUntilNextHandle = computed(() => {
      let nextDate;
      if (nextHandle.value) {
        nextDate = moment(nextHandle.value.date);
      } else {
        nextDate = momentTimezone.tz(TIMEZONE).endOf('day');
      }
      return moment().to(nextDate);
    });

    const nextTournamentDate = computed(() => {
      let date;
      if (firstHandle.value) {
        // TODO: Probably all tournaments will last 1 week
        date = moment(firstHandle.value.date).add(1, 'week');
      } else {
        // TODO: If we don't have a current active tournament,
        //  ask the user to come back tomorrow
        date = momentTimezone.tz(TIMEZONE).endOf('day');
      }
      return date;
    });

    const nextTournamentDay = computed(() => {
      return moment(nextTournamentDate.value).format('dddd');
    });

    const timeUntilNextTournament = computed(() => {
      return moment().to(nextTournamentDate.value);
    });

    async function getWeeklyTournament(): Promise<ApiResponse<Tournament>> {
      const response = await useCurrentTournament();
      const { data } = response;

      if (data) {
        weeklyTournament.value = data;
      }

      return response;
    }

    function updateHandleById(id: number, data: Partial<Handle>): void {
      if (weeklyTournament.value) {
        weeklyTournament.value.handles = weeklyHandles.value.map((handle) => {
          if (handle.id === id) {
            return {
              ...handle,
              ...data
            };
          }

          return handle;
        });
      }
    }

    async function navigateToGameEngine(roomToken: string) {
      await navigateTo(
        {
          path: config.public.gameEngineUrl,
          query: {
            roomToken
          }
        },
        {
          external: true
        }
      );
    }

    function saveRoomToken(id: number, roomToken: string) {
      const authStoreRefs = storeToRefs(useAuthStore());
      const profileId = toValue(authStoreRefs.profile.value?.id);

      if (profileId) {
        const decoded = jwtDecode<RoomTokenPayload>(roomToken);
        roomTokens.value = [
          ...roomTokens.value,
          {
            profileId,
            handleId: id,
            roomToken: roomToken,
            expiresAt: decoded.exp * 1000
          }
        ];
      }
    }

    function revalidateSavedRoomTokens() {
      if (roomTokens.value?.length) {
        roomTokens.value = roomTokens.value.filter((item) => {
          const handle = weeklyHandles.value.find(
            (handle) => handle.id === item.handleId
          );
          // Remove room tokens if Handle was played
          if (
            handle &&
            [HandleStatus.Lost, HandleStatus.Won].includes(handle.status)
          ) {
            return false;
          }
          // Remove expired room tokens (>24h)
          return item.expiresAt >= Date.now();
        });
      }
    }

    async function getSavedRoomToken(
      id: number
    ): Promise<RoomTokenData | undefined> {
      revalidateSavedRoomTokens();
      await nextTick();
      if (roomTokens.value?.length) {
        const authStoreRefs = storeToRefs(useAuthStore());
        const profileId = toValue(authStoreRefs.profile.value?.id);

        return roomTokens.value.find((item) => {
          return item.handleId === id && item.profileId === profileId;
        });
      }
    }

    async function playHandle(id: number) {
      const savedRoomTokenData = await getSavedRoomToken(id);

      if (savedRoomTokenData) {
        await navigateToGameEngine(savedRoomTokenData.roomToken);
      } else {
        const origin = useAppOrigin();

        const { data, error } = await useCreateRoom(id, {
          exitRedirectUrl: `${origin}${config.public.homePageUrl}`,
          exitButtonText: 'Back to Dashboard'
        });
        if (error) {
          toast.error(error.data?.message || 'Something went wrong');
        }
        if (data?.roomToken) {
          saveRoomToken(id, data.roomToken);
          await navigateToGameEngine(data.roomToken);
        }
      }
    }

    async function playHandleAnonymously() {
      const uuid = uuidv4();
      const origin = useAppOrigin();

      const { data, error } = await useCreateRoomAnonymously(uuid, {
        exitRedirectUrl: `${origin}/play`,
        exitButtonText: 'Exit Game'
      });
      if (error) {
        toast.error(error.data?.message || 'Something went wrong');
      }
      if (data) {
        anonymouslyPlayedHandleId.value = uuid;

        if (data.roomToken) {
          await navigateToGameEngine(data.roomToken);
        }
      }
    }

    // TODO: Will be called via a webhook after the user
    //  completes the handle deal on the game engine side
    async function updateStateAfterPlay(data: Handle) {
      const route = useRoute();
      updateHandleById(data.id, data);

      const promises: Promise<any>[] = [
        authStore.getProfile(),
        getWeeklyTournament(),
        getFourWeeksData()
      ];

      if (route?.path === '/statistic') {
        promises.push(getStatisticPageData());
      }

      await Promise.all(promises);
    }

    function resetStore() {
      weeklyTournament.value = undefined;
      // TODO: Let's not clear saved tokens for a while
      // roomTokens.value = [];
    }

    function getHandleIndexById(id: number): number {
      return weeklyHandles.value.findIndex((handle) => handle.id === id);
    }

    function getDateInTimezone(input?: MomentInput, timezone = TIMEZONE) {
      return momentTimezone(input).tz(timezone);
    }

    return {
      weeklyTournament,
      anonymouslyPlayedHandleId,
      roomTokens,
      weeklyHandles,
      firstHandle,
      currentHandle,
      ongoingHandleExists,
      nextHandle,
      timeUntilNextHandle,
      nextTournamentDate,
      nextTournamentDay,
      timeUntilNextTournament,
      getWeeklyTournament,
      updateHandleById,
      revalidateSavedRoomTokens,
      playHandle,
      playHandleAnonymously,
      updateStateAfterPlay,
      resetStore,
      getHandleIndexById,
      getDateInTimezone
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
      paths: [PLAYED_HANDLE_ID_KEY, ROOM_TOKENS_KEY]
    }
  }
);
