import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (config.public.googleTagManagerId) {
    const isDebugEnabled = config.public.googleTagManagerDebug === 'true';

    nuxtApp.vueApp.use(
      createGtm({
        id: String(config.public.googleTagManagerId),
        defer: false,
        compatibility: false,
        enabled: config.public.isProdEnv,
        debug: isDebugEnabled,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false,
        parentElement: document?.head
      })
    );
  }
});
