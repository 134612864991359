import { STORE_KEY } from '@/stores/modal/constants';
import type { Component } from 'vue';

type OnCloseCallback = () => void;

type DefaultModalProps = Record<string, any>;

interface ModalPayload<P = DefaultModalProps> {
  modal: Component;
  props?: P;
  disableBackdrop?: boolean;
  hideBackdrop?: boolean;
  onClose?: OnCloseCallback;
}

export const useModalStore = defineStore(STORE_KEY, () => {
  const modal = shallowRef<Component | null>(null);
  const props = ref<DefaultModalProps>({});
  const disableBackdrop = ref<boolean>(false);
  const hideBackdrop = ref<boolean>(false);
  const onClose = ref<OnCloseCallback | null>(null);

  const modalIsVisible = computed<boolean>(() => {
    return !!modal.value;
  });

  const showCloseButton = computed<boolean>(() => {
    return !disableBackdrop.value;
  });

  function openModal<P = DefaultModalProps>(payload: ModalPayload<P>) {
    resetValues();
    resetCallbacks();

    modal.value = payload.modal;
    if (payload.props) {
      props.value = payload.props;
    }
    if (payload.disableBackdrop) {
      disableBackdrop.value = payload.disableBackdrop;
    }
    if (payload.hideBackdrop) {
      hideBackdrop.value = payload.hideBackdrop;
    }
    if (payload.onClose) {
      onClose.value = payload.onClose;
    }
  }

  function closeModal() {
    resetValues();

    if (document) {
      document.body.removeAttribute('data-modal');
    }
    if (onClose.value) {
      onClose.value();
      nextTick().then(() => {
        resetCallbacks();
      });
    }
  }

  function resetValues() {
    modal.value = null;
    props.value = {};
    disableBackdrop.value = false;
    hideBackdrop.value = false;
  }

  function resetCallbacks() {
    onClose.value = null;
  }

  return {
    modal,
    props,
    disableBackdrop,
    hideBackdrop,
    modalIsVisible,
    showCloseButton,
    openModal,
    closeModal
  };
});
