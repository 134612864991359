import { default as coursesAcIycDB47yMeta } from "/tmp/build_38fd9a98/pages/courses.vue?macro=true";
import { default as _91id_93DXN8smSdyQMeta } from "/tmp/build_38fd9a98/pages/gift-card/[id].vue?macro=true";
import { default as _91token_93vmzb9VsBd8Meta } from "/tmp/build_38fd9a98/pages/gift-card/claim/[token].vue?macro=true";
import { default as indexsmruQoPleLMeta } from "/tmp/build_38fd9a98/pages/gift-card/index.vue?macro=true";
import { default as indexSqNG6s4LGzMeta } from "/tmp/build_38fd9a98/pages/index.vue?macro=true";
import { default as loginNZI7kQOCVqMeta } from "/tmp/build_38fd9a98/pages/login.vue?macro=true";
import { default as playkgzBGoEkueMeta } from "/tmp/build_38fd9a98/pages/play.vue?macro=true";
import { default as handle8BXNCLTCsoMeta } from "/tmp/build_38fd9a98/pages/settings/handle.vue?macro=true";
import { default as indexF3vVQkd2bKMeta } from "/tmp/build_38fd9a98/pages/settings/index.vue?macro=true";
import { default as sign_45upt4erfTDatfMeta } from "/tmp/build_38fd9a98/pages/sign-up.vue?macro=true";
import { default as statisticZw93rcgaWSMeta } from "/tmp/build_38fd9a98/pages/statistic.vue?macro=true";
import { default as tournamentNOBYribPztMeta } from "/tmp/build_38fd9a98/pages/tournament.vue?macro=true";
export default [
  {
    name: coursesAcIycDB47yMeta?.name ?? "courses",
    path: coursesAcIycDB47yMeta?.path ?? "/courses",
    meta: coursesAcIycDB47yMeta || {},
    alias: coursesAcIycDB47yMeta?.alias || [],
    redirect: coursesAcIycDB47yMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DXN8smSdyQMeta?.name ?? "gift-card-id",
    path: _91id_93DXN8smSdyQMeta?.path ?? "/gift-card/:id()",
    meta: _91id_93DXN8smSdyQMeta || {},
    alias: _91id_93DXN8smSdyQMeta?.alias || [],
    redirect: _91id_93DXN8smSdyQMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/gift-card/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93vmzb9VsBd8Meta?.name ?? "gift-card-claim-token",
    path: _91token_93vmzb9VsBd8Meta?.path ?? "/gift-card/claim/:token()",
    meta: _91token_93vmzb9VsBd8Meta || {},
    alias: _91token_93vmzb9VsBd8Meta?.alias || [],
    redirect: _91token_93vmzb9VsBd8Meta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/gift-card/claim/[token].vue").then(m => m.default || m)
  },
  {
    name: indexsmruQoPleLMeta?.name ?? "gift-card",
    path: indexsmruQoPleLMeta?.path ?? "/gift-card",
    meta: indexsmruQoPleLMeta || {},
    alias: indexsmruQoPleLMeta?.alias || [],
    redirect: indexsmruQoPleLMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/gift-card/index.vue").then(m => m.default || m)
  },
  {
    name: indexSqNG6s4LGzMeta?.name ?? "index",
    path: indexSqNG6s4LGzMeta?.path ?? "/",
    meta: indexSqNG6s4LGzMeta || {},
    alias: indexSqNG6s4LGzMeta?.alias || [],
    redirect: indexSqNG6s4LGzMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginNZI7kQOCVqMeta?.name ?? "login",
    path: loginNZI7kQOCVqMeta?.path ?? "/login",
    meta: loginNZI7kQOCVqMeta || {},
    alias: loginNZI7kQOCVqMeta?.alias || [],
    redirect: loginNZI7kQOCVqMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/login.vue").then(m => m.default || m)
  },
  {
    name: playkgzBGoEkueMeta?.name ?? "play",
    path: playkgzBGoEkueMeta?.path ?? "/play",
    meta: playkgzBGoEkueMeta || {},
    alias: playkgzBGoEkueMeta?.alias || [],
    redirect: playkgzBGoEkueMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/play.vue").then(m => m.default || m)
  },
  {
    name: handle8BXNCLTCsoMeta?.name ?? "settings-handle",
    path: handle8BXNCLTCsoMeta?.path ?? "/settings/handle",
    meta: handle8BXNCLTCsoMeta || {},
    alias: handle8BXNCLTCsoMeta?.alias || [],
    redirect: handle8BXNCLTCsoMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/settings/handle.vue").then(m => m.default || m)
  },
  {
    name: indexF3vVQkd2bKMeta?.name ?? "settings",
    path: indexF3vVQkd2bKMeta?.path ?? "/settings",
    meta: indexF3vVQkd2bKMeta || {},
    alias: indexF3vVQkd2bKMeta?.alias || [],
    redirect: indexF3vVQkd2bKMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45upt4erfTDatfMeta?.name ?? "sign-up",
    path: sign_45upt4erfTDatfMeta?.path ?? "/sign-up",
    meta: sign_45upt4erfTDatfMeta || {},
    alias: sign_45upt4erfTDatfMeta?.alias || [],
    redirect: sign_45upt4erfTDatfMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: statisticZw93rcgaWSMeta?.name ?? "statistic",
    path: statisticZw93rcgaWSMeta?.path ?? "/statistic",
    meta: statisticZw93rcgaWSMeta || {},
    alias: statisticZw93rcgaWSMeta?.alias || [],
    redirect: statisticZw93rcgaWSMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/statistic.vue").then(m => m.default || m)
  },
  {
    name: tournamentNOBYribPztMeta?.name ?? "tournament",
    path: tournamentNOBYribPztMeta?.path ?? "/tournament",
    meta: tournamentNOBYribPztMeta || {},
    alias: tournamentNOBYribPztMeta?.alias || [],
    redirect: tournamentNOBYribPztMeta?.redirect,
    component: () => import("/tmp/build_38fd9a98/pages/tournament.vue").then(m => m.default || m)
  }
]