<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth';

const config = useRuntimeConfig();
const { identifyUserOnMounted } = useAuthStore();

const isGTMEnabled = computed(() => {
  return config.public.isProdEnv && config.public.googleTagManagerId;
});

useHead({
  titleTemplate: (titleChunk) => {
    const baseTitle = 'HANDLE – A Daily Deal to Test Your Card-Play';
    return titleChunk ? `${titleChunk} – ${baseTitle}` : baseTitle;
  },
  noscript: [
    {
      innerHTML: isGTMEnabled.value
        ? `<iframe
              src="https://www.googletagmanager.com/ns.html?id=${config.public.googleTagManagerId}"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>`
        : '',
      tagPosition: 'bodyOpen'
    }
  ]
});

onMounted(() => {
  identifyUserOnMounted();
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
