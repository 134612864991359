
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "906aafdb-0293-489d-9efb-134ae5541b19"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/tmp/build_38fd9a98/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
