<script lang="ts" setup>
import type { ComponentPublicInstance } from 'vue';
import { cva } from 'class-variance-authority';
import { onClickOutside } from '@vueuse/core';
import { useModalStore } from '@/stores/modal';

const { showCloseButton, hideBackdrop } = storeToRefs(useModalStore());
const { closeModal } = useModalStore();

enum Sizes {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md'
}

interface Props {
  title?: string;
  size?: Sizes;
  showHeader?: boolean;
  applyTextStyle?: boolean;
  applyMaxHeight?: boolean;
  className?: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: Sizes.Medium,
  showHeader: true,
  applyTextStyle: true,
  applyMaxHeight: true
});

const modalRef = ref<ComponentPublicInstance<HTMLDivElement> | null>(null);

const modalClasses = computed(() => {
  return cva(['modal', props.className], {
    variants: {
      size: {
        [Sizes.ExtraSmall]: 'w-80',
        [Sizes.Small]: 'w-[510px]',
        [Sizes.Medium]: 'w-[780px]'
      },
      hideBackdrop: {
        true: 'fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg'
      },
      applyMaxHeight: {
        true: 'modal-max-height'
      }
    }
  })({
    size: props.size,
    hideBackdrop: hideBackdrop.value,
    applyMaxHeight: props.applyMaxHeight
  });
});

onClickOutside(modalRef, () => {
  if (hideBackdrop.value) {
    closeModal();
  }
});
</script>

<template>
  <div ref="modalRef" :class="['modal', modalClasses]">
    <div
      v-if="showHeader"
      class="flex items-center justify-between bg-white py-3 px-4 lg:px-5 text-brand-navy-700 font-medium text-lg leading-6 rounded-t-[10px]"
    >
      <slot name="title">{{ title }}</slot>
      <button v-if="showCloseButton" class="" @click="closeModal">
        <Icon name="ant-design:close-outlined" size="22" />
      </button>
    </div>
    <div
      :class="[
        'modal-body',
        !showHeader && 'rounded-t-[10px]',
        applyTextStyle && 'rich-text-style'
      ]"
    >
      <slot v-bind="{ close: closeModal }" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  @apply flex flex-col max-w-full;

  &.modal-max-height {
    max-height: calc(100vh - theme('spacing.10'));
  }
}

:deep(.modal-body) {
  @apply grid gap-4 bg-brand-light-gray p-4 lg:p-5 text-brand-navy-600 rounded-b-[10px] overflow-y-auto;

  &.rich-text-style {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply font-medium;
    }

    h1 {
      @apply text-[22px] leading-7;
    }

    h2 {
      @apply text-lg leading-6;
    }

    h3 {
      @apply text-base leading-6;
    }

    p {
      @apply text-base leading-5;
    }

    ul,
    ol {
      @apply ml-6;

      li {
        @apply text-base leading-7;
      }
    }

    ul {
      @apply list-disc [&_ul]:list-[revert];
    }

    ol {
      @apply list-decimal;
    }

    a {
      @apply underline;
    }
  }
}
</style>
