import { useBaseApi } from '@/composables/api';
import type {
  CreateRoomPayload,
  CreateRoomResponse,
  AnonymousScoreExistsResponse
} from '@/types/tournaments';
import type { DealResult } from '@/types/scores';

export const useCreateRoom = (id: number, payload: CreateRoomPayload) => {
  return useBaseApi<CreateRoomResponse>(`/handles/${id}/create-room`, {
    method: 'post',
    body: payload
  });
};

export const useCreateRoomAnonymously = (
  uuid: string,
  payload: CreateRoomPayload
) => {
  return useBaseApi<CreateRoomResponse>('/handles/create-anonymous-room', {
    method: 'post',
    body: { uuid, ...payload }
  });
};

export const useAnonymousScoreExists = (uuid: string) => {
  return useBaseApi<AnonymousScoreExistsResponse>(
    `/scores/anonymous-score-exists/${uuid}`,
    {
      method: 'get'
    }
  );
};

export const useGetDealResult = (id: number) => {
  return useBaseApi<DealResult>(`/handles/${id}/detailed-result`, {
    method: 'get'
  });
};
